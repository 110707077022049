<template>
    <div
        class="hide-scrollbar"
        style="overflow-y: scroll; width: 100%; background-color: #f7f5f9; height: 100%; padding: 0 25px 25px;"
    >
        <a-modal :visible="addUser" @cancel="closeUser" centered :width="'50%'">
            <template slot="footer">
                <a-button
                    style="width: 100px"
                    class="cancel-button"
                    @click="closeUser"
                >
                    CANCEL
                </a-button>
                <a-button
                    style="width: 100px"
                    type="primary"
                    :loading="loading"
                    @click="inviteUser"
                >
                    SEND
                </a-button>
            </template>
            <h4 class="mb-5">Invite Users</h4>
            <a-form>
                <vue-custom-scrollbar style="min-height: 250px; width: 100%">
                    <div v-for="(person, personI) in inviteList" :key="person + personI">
                        <a-row :gutter="16">
                            <a-col :span="12">
                                <a-form-item
                                    required
                                    v-if="personI === 0"
                                    label="Email Address"
                                >
                                    <a-input
                                        v-model="person.email"
                                        placeholder="Email address"
                                        required
                                    />
                                </a-form-item>
                                <a-input
                                    style="margin-bottom: 24px"
                                    v-else
                                    v-model="person.email"
                                    placeholder="Email address"
                                    required
                                />
                            </a-col>
                            <a-col :span="11">
                                <a-form-item
                                    required
                                    v-if="personI === 0"
                                    label="Role"
                                >
                                    <a-select v-model="person.role" required>
                                        <a-select-option
											v-for="(role, roleI) in roles"
                                            :value="role.id"
                                            :key="roleI"
                                        >
                                            <template
                                                v-if="
                                                    role.name === 'bh_admin'
                                                "
                                            >
                                                Admin
                                            </template>
											<template
                                                v-else-if="
                                                    role.name === 'bh_agent'
                                                "
                                            >
												VIP Realtor
                                            </template>
											<template
                                                v-else-if="
                                                    role.name === 'bh_agent_manager'
                                                "
                                            >
												VIP Broker Manager
                                            </template>
                                            <template v-else>
                                                {{ role.name }}
                                            </template>
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-select v-else v-model="person.role" required>
                                    <a-select-option
										v-for="(role, roleI) in roles"
                                        :value="role.id"
                                        :key="roleI"
                                    >
                                        <template
                                            v-if="role.name === 'bh_admin'"
                                        >
                                            Admin
                                        </template>
										<template
                                            v-else-if="
                                                role.name === 'bh_agent'
                                            "
                                        >
											VIP Realtor
                                        </template>
										<template
                                            v-else-if="
                                                role.name === 'bh_agent_manager'
                                            "
                                        >
											VIP Broker Manager
                                        </template>
                                        <template v-else>
                                            {{ role.name }}
                                        </template>
                                    </a-select-option>
                                </a-select>
                            </a-col>
                            <a-col
                                :span="1"
                                v-if="personI !== 0"
                                style="margin-top: 7px"
                            >
                                <svg
                                    @click="inviteList.splice(personI, 1)"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="
                                        feather feather-trash-2
                                        action-buttons
                                    "
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                    ></path>
                                    <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                    ></line>
                                    <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                    ></line>
                                </svg>
                            </a-col>
                        </a-row>
                    </div>
                </vue-custom-scrollbar>
            </a-form>

            <div
                style="color: var(--orange); cursor: pointer; width: 120px"
                @click="
                    roles.length && roles[0].id
                        ? inviteList.push({ email: '', role: `${roles[0].id}` })
                        : inviteList.push({ email: '', role: '' })
                "
            >
                <a-icon
                    type="plus-circle"
                    style="color: var(--orange)"
                    class="mr-2"
                />
                Add another
            </div>
        </a-modal>

		<a-modal title="Bulk Invite Users" :visible="bulkInviteModal" @cancel="closeBulkInvite" centered :footer="null">
			<div v-if="!bulkInvite.emails.length">
				<a-upload-dragger :showUploadList="false" :beforeUpload="beforeUpload"  :disabled="processingFile">
					<svg xmlns="http://www.w3.org/2000/svg" width="63.43" height="84.573"
						viewBox="0 0 63.43 84.573">
						<path id="Icon_awesome-file-upload" data-name="Icon awesome-file-upload" fill="#b0b1b5"
						d="M37,22.465V0H3.964A3.955,3.955,0,0,0,0,3.964V80.609a3.955,3.955,0,0,0,3.964,3.964h55.5a3.955,3.955,0,0,0,3.964-3.964V26.429H40.965A3.976,3.976,0,0,1,37,22.465ZM47.767,58.146H37V71.36A2.642,2.642,0,0,1,34.358,74H29.072a2.642,2.642,0,0,1-2.643-2.643V58.146H15.663A2.643,2.643,0,0,1,13.8,53.626L29.728,37.818a2.816,2.816,0,0,1,3.971,0L49.625,53.626A2.642,2.642,0,0,1,47.767,58.146Zm14.506-40.8L46.1,1.156A3.961,3.961,0,0,0,43.294,0H42.286V21.143H63.43V20.136A3.952,3.952,0,0,0,62.273,17.344Z" />
					</svg>
					<p class="mt-3">Click or drag file to this area to upload</p>
				</a-upload-dragger>
				<h6 class="mt-3 text-center">Upload CSV file to bulk invite users</h6>
			</div>
			<div v-else-if="!processingFile && bulkInvite.emails.length">
				<h6> <strong>{{ bulkInvite.emails.length }} emails </strong> will be invited with below role.</h6>
				<a-form-model :model="bulkInvite" ref="bulkInvite" class="mt-3">
					<a-row type="flex" :gutter="24">
						<a-col :span="24" :xl="12">
							<a-form-model-item label="Bulk User Role" prop="role" :rules="req('Please select user role')">
								<a-select v-model="bulkInvite.role" size="large">
                                    <a-select-option v-for="(role, roleI) in roles" :value="role.id" :key="roleI">
										<template v-if=" role.name === 'bh_admin'"> Admin </template>
										<template v-else-if=" role.name === 'bh_agent'"> VIP Realtor </template>
										<template v-else-if=" role.name === 'bh_agent_manager'"> VIP Broker Manager </template>
                                        <template v-else> {{ role.name }} </template>
                                    </a-select-option>
                                </a-select>
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>
				<div class="dF" style="justify-content: flex-end">
					<a-button @click="closeBulkInvite">CANCEL</a-button>
					<a-button @click="bulkInviteEmails" type="primary" class="ml-3">SEND BULK INVITATION</a-button>
				</div>
			</div>
		</a-modal>

		<a-modal title="Export Roles to Existing Project" :visible="exportModal.visible" okText="EXPORT"
			@cancel="exportModal.visible = false" @ok="exportRoles" centered :confirmLoading="loading">
			<a-row :gutter="16">
				<a-form-model ref="exportModal" :model="exportModal">
					<a-col :span="24">
						<a-form-model-item prop="instance" label="Project" required
							:rules="req('Please select the instance')">
							<a-select v-model="exportModal.instance" placeholder="Select a Project" size="large"
								show-search option-filter-prop="children" :filter-option="filterOption">
								<a-select-option v-for="inst in instances" :value="inst.id" :key="inst.id">{{ inst.name
								}}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
		</a-modal>

		<div class="dF aC jSB mb-3">
			<h3>Roles and Users</h3>

			<div class="dF aC" style="gap: 30px;">
				<a-dropdown placement="bottomRight" style="color: var(--primary); border-color: var(--primary); font-size: large;">
					<a-menu slot="overlay" @click="onMenuClick">
						<a-menu-item key="Add User"> <a-icon type="user-add" />Invite New User</a-menu-item>
						<a-menu-item key="Bulk Invite"> <a-icon type="file-add" />Invite with CSV</a-menu-item>
					</a-menu>
					<a-button style="margin-left: 8px"> Invite Actions <a-icon type="down" /> </a-button>
				</a-dropdown>
				<a-dropdown v-if="$p >= 40" placement="bottomRight" style="color: var(--primary); border-color: var(--primary); font-size: large;">
					<a-menu slot="overlay" @click="onMenuClick">
						<a-menu-item key="Add Role"> <a-icon type="plus" />Add New Role</a-menu-item>
						<a-menu-item key="Export Roles"> <a-icon type="export" />Export Roles</a-menu-item>
					</a-menu>
					<a-button style="margin-left: 8px"> Role Actions <a-icon type="down" /> </a-button>
				</a-dropdown>
			</div>
		</div>

        <div class="dF">
            <div class="dF hide-scrollbar" style="flex: 1; overflow-x: scroll">
                <a-table
                    :columns="columns"
                    :dataSource="users"
                    :row-key="(e) => e.id"
                    class="users-table"
                    style="flex: 1"
                    :pagination="false"
					:loading="loading"
                >
                    <template slot="title">
                        <div class="dF aB">
                            <div
                                style="color: black; font-size: 16px"
                                class="mr-3"
                            >
                                Users
                            </div>
                            <div
                                style="
                                    color: #9ea0a5;
                                    font-size: 12px;
                                    margin-top: 4px;
                                "
                            >
                                {{ users.length }} total
                            </div>
                        </div>
                    </template>

                    <div slot="fullName" slot-scope="user" class="dF aC">
                        <div class="mr-3">
                            <div
                                class="air__utils__avatar"
                                v-if="user.user && user.user.avatar"
                            >
                                <img :src="user.user.avatar" alt="User" />
                            </div>
                            <a-avatar
                                v-else
                                shape="circle"
                                size="large"
                                :class="$style.avatar"
                                >{{ user.fullName && user.fullName[0] && user.fullName[0].toUpperCase() }}</a-avatar
                            >
                        </div>
                        {{ user.fullName }}
                    </div>

                    <template slot="role" slot-scope="obj">
                        <template v-if="obj.role !== null">
                            <span v-if="obj.owner">Project & Billing Owner</span>
                            <span v-else-if="obj.projectOwner">Project Owner</span>
                            <span v-else-if="obj.billingOwner">Billing Owner</span>
                            <span v-else-if="obj.user && obj.user.id === user.user.id">{{ getRoleName(obj.role.name) }}</span>
                            <span v-else-if="obj.invite">{{getRoleName(obj.role.name)}}</span>
							<span v-else-if="obj.role.name === 'bh_agent'">{{getRoleName(obj.role.name)}}</span>
                            <a-dropdown v-else-if="$p >= 50 || !['bh_admin', 'bh_agent', 'bh_agent_manager'].includes(obj.role.name)" style="cursor: pointer">
                                <div class="ant-dropdown-link">
                                    {{ getRoleName(obj.role.name)}}<a-icon class="ml-3" type="down"/>
                                </div>
                                <a-menu
                                    slot="overlay"
                                    @click="changeRole(obj, $event)"
                                >
                                    <a-menu-item
                                        v-for="role in roles"
                                        :key="role.id"
                                    >
                                        <span>{{
                                            getRoleName(role.name)
                                        }}</span>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
							<span v-else-if="obj.role.name === 'bh_agent_manager'">{{getRoleName(obj.role.name)}}</span>
                        </template>
                        <template v-else-if="obj.invite">{{obj.role}}</template>
                        <template v-else-if="$p >= 50 || !['bh_admin', 'bh_agent', 'bh_agent_manager'].includes(obj.role.name)">
                            <a-dropdown style="cursor: pointer">
                                <div class="ant-dropdown-link">
                                    No role<a-icon class="ml-3" type="down"/>
                                </div>
                                <a-menu
                                    slot="overlay"
                                    @click="changeRole(obj, $event)"
                                >
                                    <a-menu-item
                                        v-for="role in roles"
                                        :key="role.id"
                                    >
                                        <span>{{
                                            getRoleName(role.name)
                                        }}</span>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </template>
                    </template>

                    <div
                        slot="email"
                        slot-scope="user"
                        class="dF"
                        style="line-height: 40px"
                    >
                        <template v-if="user.user">
                            {{ user.user.email }}
                        </template>
                        <template v-else>
                            {{ user.email }}
                        </template>
                    </div>

                    <div
                        slot="create"
                        slot-scope="user"
                        class="dF"
                        style="line-height: 40px; color: #9ea0a5"
                    >
                        <template v-if="user.user">
                            {{ formatDate(user.user.createdAt) }}
                        </template>
                        <template v-else> N/A </template>
                    </div>

                    <div
                        slot="lastActive"
                        slot-scope="user"
                        class="dF"
                        style="line-height: 40px; color: #9ea0a5"
                    >
                        <template
                            v-if="
                                user.user &&
                                user.user.settings &&
                                user.user.settings.lastVisited &&
                                user.user.settings.lastVisited[storeInstance.id]
                            "
                        >
							{{ convertDate(user.user.settings.lastVisited[storeInstance.id]) }}
                        </template>
                        <template v-else> N/A </template>
                    </div>

                    <div
                        slot="roleUpdate"
                        slot-scope="user"
                        class="dF"
                        style="line-height: 40px; color: #9ea0a5"
                    >
                        <template v-if="user.user && user.user.roleUpdatedAt">
                            {{ convertDate(user.user.roleUpdatedAt) }}
                        </template>
                        <template v-else> N/A </template>
                    </div>

                    <div
                        slot="action1"
                        slot-scope="user"
                        class="dF"
                        style="line-height: 40px"
                    >

						<a-tooltip v-if="user.invite" title="Notifies user through autoresponder that they have an invite pending." overlayClassName="change-tooltip-color">
							<a-icon type="mail"  @click="notifyUser(user)" style="font-size: 20px;"/>
						</a-tooltip>
                    </div>

                    <div
                        slot="action2"
                        slot-scope="obj"
                        class="dF aC jSB"
                        style="line-height: 40px"
                    >
						<a-icon v-if="obj.invite || (!(obj.owner || obj.projectOwner || obj.billingOwner) && obj.user && obj.user.id !== user.user.id && !(isUserBrokerManager && obj.role.name === 'bh_agent_manager'))" @click="revokeAccess(obj)" type="delete" style="color: red; font-size: 20px;" />

						<a-button v-if="(obj.owner || obj.projectOwner) && instance.billingOwner === user.user.id" @click="changeProjectOwnerModal = true">
							Change Project Owner
						</a-button>
                    </div>

                    <template slot="status" slot-scope="obj">
                        <div
                            v-if="!(obj.owner || obj.projectOwner || obj.billingOwner)"
                            class="status"
                            :style="[
                                obj.access
                                    ? { 'background-color': 'var(--success)' }
                                    : obj.invite
                                    ? { 'background-color': 'var(--danger)' }
                                    : { 'background-color': 'red' },
                            ]"
                        >
                            {{
                                obj.access
                                    ? "ACTIVE"
                                    : obj.invite
                                    ? "PENDING"
                                    : "NO ACCESS"
                            }}
                        </div>
                    </template>
                </a-table>
            </div>
        </div>
		<a-modal
            v-model="changeProjectOwnerModal"
            title="Change Project Owner"
            ok-text="UPDATE"
            @ok="updateProjectOwner"
            :confirmLoading="loading"
            :ok-button-props="{
                props: {
                    disabled: !updatedProjectOwner.owner,
                },
            }"
        >
            <BhLoading :show="loading" />
            <a-form-model ref="ruleForm" :model="updatedProjectOwner">
                <a-form-model-item
                    label="New Project Owner"
                    prop="owner"
                    required
                    :rules="req('Please choose a New Project Owner')"
                >
                    <a-select
                        show-search
                        placeholder="Choose a New Project Owner"
                        option-filter-prop="email"
                        :filter-option="filterOption"
                        v-model="updatedProjectOwner.owner"
                    >
                        <a-select-option
                            v-for="user in filterUsers"
                            :key="user.id"
                            :value="user.id"
                        >
                           {{ user.firstName }} {{ user.lastName }} ({{ user.email }})
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { getRoleName } from "bh-mod";
import vueCustomScrollbar from "vue-custom-scrollbar";
import BhLoading from "bh-mod/components/common/Loading";

export default {
	components: {
		BhLoading,
		vueCustomScrollbar,
	},
	data() {
		return {
			loading: false,
			changeProjectOwnerModal: false,
			updatedProjectOwner: {
				owner: "",
			},
			inviteList: [],
			invite: {
				email: "",
				role: "",
			},
			role: {
				id: null,
				name: "",
			},
			instance: {},

			switchRole: {
				obj: "",
				role: "",
			},
			revoke: {},
			realtorRole: {},
			columns: [
				{
					title: "User Name",
					key: "fullName",
					scopedSlots: { customRender: "fullName" },
				},
				{
					title: "Role",
					key: "role",
					scopedSlots: { customRender: "role" },
				},
				{
					title: "Status",
					key: "status",
					scopedSlots: { customRender: "status" },
				},
				{
					title: "Email Address",
					key: "email",
					scopedSlots: { customRender: "email" },
				},
				{
					title: "Account Created",
					key: "create",
					scopedSlots: { customRender: "create" },
				},
				{
					title: "Last Active",
					key: "lastActive",
					scopedSlots: { customRender: "lastActive" },
				},
				{
					title: "Role Updated",
					key: "roleUpdate",
					scopedSlots: { customRender: "roleUpdate" },
				},
				{
					title: "",
					key: "action1",
					scopedSlots: { customRender: "action1" },
				},
				{
					title: "",
					key: "action2",
					scopedSlots: { customRender: "action2" },
				},
			],
			exportModal: {
				visible: false,
				instance: null,
			},
			instances: [],
			bulkInviteModal: false,
			processingFile: false,
			bulkInvite: {
				role: null,
				emails: []
			}
		};
	},
	watch: {
		addUser(val) {
			if (val) {
				if (this.roles.length && this.roles[0].id)
					this.inviteList = [
						{ email: "", role: `${this.roles[0].id}` },
					];
				else this.inviteList = [{ email: "", role: "" }];
			}
		},
		editRole: {
			handler(val) {
				let role = this.roles.find((x) => x.id == val.role);
				this.role.name = role.name;
				this.role.id = role.id;
				this.permissions = role.permissions;
				this.$store.commit("EDIT_ROLE", {
					...this.role,
					permissions: this.permissions,
				});
			},
			deep: true,
		},
		deleteRole: {
			handler(val) {
				let role = this.roles.find((x) => x.id == val.role);
				let self = this;
				this.$confirm({
					title: "Delete Role",
					content: (h) => <div>Do you want to delete this role?</div>,
					okText: "DELETE",
					okType: "danger",
					cancelText: "CANCEL",
					centered: true,
					onOk() {
						self.confirmDeleteRole(role);
					},
					onCancel() {
						console.log("CANCELLED");
					},
				});
			},
			deep: true,
		},
	},
	computed: {
		...mapState(["user"]),
		dateFormat() {
			return this.$store.state.appData.allSettings.user && this.$store.state.appData.allSettings.user.options && this.$store.state.appData.allSettings.user.options.regional && this.$store.state.appData.allSettings.user.options.regional.dateFormat ? this.$store.state.appData.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
		editRole() {
			return this.$store.state.editRole;
		},
		deleteRole() {
			return this.$store.state.deleteRole;
		},
		selectedRole() {
			return this.$store.state.selectedRole;
		},
		roleCounts() {
			if (this.roles && this.roles.length) {
				return this.$store.state.roleCounts;
			} else {
				return {};
			}
		},
		roles() {
			let roles = this.$store.state.roles

			if(this.isUserBrokerManager) {
				roles = roles.filter(role => !['bh_admin', 'bh_agent_manager'].includes(role.name))
			} else if (this.$p < 50) {
				roles = roles.filter(role => !['bh_admin', 'bh_agent', 'bh_agent_manager'].includes(role.name))
			} else if (this.instance.package && !["PA-COM-LRP", "PA-CON-HRP"].includes(this.instance.package.itemCode)) {
				roles = roles.filter(r => !['bh_agent', 'bh_agent_manager'].includes(r.name))
			}
			return this.order(roles);
		},
		storeInstance() {
			return this.$store.state.instance;
		},
		addUser() {
			return this.$store.state.addUser.show;
		},
		users() {
			if (!this.instance || !this.instance.users) return [];
			let owner = {};
			let billingOwner = {};
			let users = this.instance.users || [];
			users.forEach(
				(x) =>
				(x.fullName =
					x.user.firstName + " " + x.user.lastName || "")
			);
			users = users.filter((user) => {
				if (user.user.id === this.instance.owner) {
					if (user.user.id === this.instance.billingOwner) {
						user.owner = true;
					} else {
						user.projectOwner = true;
					}
					owner = user;
					return false;
				} else if (user.user.id === this.instance.billingOwner) {
					user.billingOwner = true;
					billingOwner = user;
					return false;
				} else {
					user.access = true;
					return true;
				}
			});

			let invites = this.instance.invites;
			if (invites && Array.isArray(invites)) {
				invites = invites.map((inv) => {
					inv.fullName = inv.email;
					inv.invite = true;
					return inv;
				});
			}
			invites = invites.sort((a, b) => {
				if (a.fullName < b.fullName) return -1;
				if (a.fullName > b.fullName) return 1;
				else return 0;
			});
			users = users.sort((a, b) => {
				if (a.fullName < b.fullName) return -1;
				if (a.fullName > b.fullName) return 1;
				else return 0;
			});

			let allUsers = [];
			if (owner.id) {
				allUsers.push(owner)
			}

			if (billingOwner.id) {
				allUsers.push(billingOwner)
			}
			allUsers.push(...[...invites, ...users])

			if (this.selectedRole) {
				allUsers = allUsers.filter(u => {
					const role = u.role && u.role.id;
					return role === this.selectedRole
				})
			}
			return allUsers;
		},
		agentRole() {
			return this.roles.find(r => r.name === "bh_agent");
		},

		filterUsers() {
			let users = this.instance.users || [];
			users = users.filter(u => u.role && u.role.name === 'bh_admin').map(u => u.user)
			if (this.changeProjectOwnerModal) {
				return users.filter((u) => u.id !== this.instance.owner);
			}
			return users;
		},

		isUserBrokerManager() {
			let roles = this.$store.state.roles
			let brokerManagerRole = roles.find(r => r.name === 'bh_agent_manager')

			let users = this.instance && this.instance.users || [];
			let user = this.$store.state.user.user || null
			let currentUser =  users.find(u => (u.user && u.user.id) === (user && user.id))

			return (currentUser && brokerManagerRole && currentUser.role && currentUser.role.id === brokerManagerRole.id) ? true : false
		}
	},
	methods: {
		req: (msg) => ({ required: true, message: msg }),

		onMenuClick(action) {
			if (action.key === 'Add User') {
				this.$store.commit("ADD_USER")
			} else if (action.key === 'Bulk Invite') {
				this.bulkInviteModal = true;
			} else if (action.key === 'Add Role') {
				this.$store.commit("ADD_ROLE_MODAL")
			} else if (action.key === 'Export Roles') {
				this.exportModal.visible = true
			}
		},

		computeRoleCounts() {
			let obj = {};
			let users = this.$store.state.instance.users || [];

			users.forEach((user) => {
				if (!obj[user.role]) obj[user.role] = 0;
				obj[user.role]++;
			});
			return obj;
		},
		order(roles) {
			let orderArray = [];
			let admin = {};
			let manager = {};
			let realtor = {};
			roles.forEach((x) => {
				if (x.name === "bh_admin") {
					admin = x;
				} else if (x.name === "bh_agent_manager") {
					manager = x;
				} else if (x.name === "bh_agent") {
					realtor = x;
					this.realtorRole = x;
				} else {
					orderArray.push(x);
				}
			});
			orderArray.sort((a, b) => {
				if (a.name > b.name) return 1;
				if (a.name < b.name) return -1;
				else return 0;
			});
			let list = [];
			if (this.isUserBrokerManager) {
				if (realtor && realtor.id) {
					list.push(realtor);
				}
			} else if (this.$p >= 50) {
				list.push(admin);
				if (this.instance.package && ["PA-COM-LRP", "PA-CON-HRP"].includes(this.instance.package.itemCode)) {
					list.push(manager);
					list.push(realtor);
				}
			}
			orderArray.forEach((x) => {
				list.push(x);
			});
			return list;
		},
		selectRole(e, i) {
			this.inviteList[i.key].role = e;
		},

		getRoleName,
		notifyUser(user) {
			let self = this;
			this.$confirm({
				title: "Notify User",
				content: (h) => <div>Do you want to resend a notification to the user that they have an invitation pending for this project?</div>,
				okText: "CONFIRM",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.$api
						.post(
							`/user-invites/${self.instance.id}/${user.id}/resend`
						)
						.then(() => {
							self.$message.success(`Successfully send notification to user for pending invitation.`);
						})
						.catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err, "Error while notifying user. Please try again!"))
							}
						});
				},
				onCancel() {
					console.log("CANCELLED");
				},
			});
		},

		confirmDeleteRole(role) {
			this.$api
				.delete(`/permissions/${this.instance.id}/${role.id}`)
				.then(({ data }) => {
					if (data.id) {
						let roles = this.roles.filter((x) => x.id !== data.id);
						this.$store.commit("SET_ROLES", roles);
						this.$message.success(
							`Successfully deleted selected role ${role.name}`
						);
						this.fetchUsers()
					}
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
		},
		changeRole(obj, { key }) {
			if (obj.role === null) console.log("NULL ROLE");
			else if (obj.role.id === key) return this.$message.warn('Current role is same you select. Please select different role to update it.');
			else if (key === (this.agentRole && this.agentRole.id)) return this.$message.error('You can not update user role "VIP Realtor" to exiting user. Please add new user for it.')
			this.switchRole.obj = obj;
			this.switchRole.role = this.roles.find((x) => x.id === key);

			if (!this.switchRole.role)
				return console.error("SOMETHING WENT WRONG");

			let self = this;
			this.$confirm({
				title: "Change Role",
				content: (h) => (
					<div>
						Are you sure you want to change this role?
					</div>
				),
				okText: "CONFIRM",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.confirmChangeRole();
				},
				onCancel() {
					console.log("CANCELLED");
				},
			});
		},
		inviteUser() {
			let valid = true;
			this.inviteList.forEach((x) => {
				if (x.email == "") {
					valid = false;
				}
			});
			if (valid) {
				for (var i = 0; i < this.inviteList.length; i++) {
					this.invite.role = this.inviteList[i].role;
					this.invite.email = this.inviteList[i].email;

					this.$api
						.post(
							`/user-invites/${this.instance.id}/${this.invite.role}?email=${this.invite.email}${this.invite.role === this.realtorRole.id ? '&type=agent' : ''}`
						)
						.then(({ data }) => {
							this.$message.success(`Invite sent to ${data.email}!`);
							this.fetchUsers()
							if (!this.instance.invites)
								this.instance.invites = [];
							data.user = {
								email: data.email,
								createdAt: "",
								updatedAt: "",
							};
							let object = JSON.parse(JSON.stringify(data));
							object.role = this.invite.role;
							this.instance.invites.push(object);
							this.closeUser();
						})
						.catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err));
							}
						});
				}
			} else {
				this.$message.error("An error occurred!");
			}
		},
		revokeAccess(user) {
			this.revoke = user;
			let email = "";
			if (this.revoke.invite) email = this.revoke.email;
			else email = this.revoke.user.email;
			let self = this;

			this.$confirm({
				title: "Revoke Access",
				content: (h) => (
					<div>
						Do you want to revoke access to <b>{email}?</b>
					</div>
				),
				okText: "REVOKE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.confirmRevoke();
				},
				onCancel() {
					console.log("CANCELLED");
				},
			});
		},
		confirmRevoke() {
			let email = "";
			if (this.revoke.invite) email = this.revoke.email;
			else email = this.revoke.user.email;
			let query = `?email=${email}`;
			if (this.revoke.invite) query += `&invite=${this.revoke.id}`;
			this.$api
				.delete(`/instances/${this.instance.id}/revoke${query}`)
				.then(() => {
					this.$message.success(`Successfully Revoked Access to ${this.revoke.fullName}!`);
					this.fetchUsers()
					if (this.revoke.invite) {
						this.instance.invites = this.instance.invites.filter(
							(x) => x.id !== this.revoke.id
						);
					} else {
						this.instance.users = this.instance.users.filter(
							(x) => x.id !== this.revoke.id
						);
					}
				})
				.catch((err) => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, "Error while revoking user access. Please try again!"))
					}
				});
		},
		formatDate(date) {
			let year = date.substring(0, 4)
			let month = date.substring(5, 7)
			let day = date.substring(8, 10)
			if (this.dateFormat == 'YYYY/MM/DD') return `${year}/${month}/${day}`
			else if (this.dateFormat == 'DD/MM/YYYY') return `${day}/${month}/${year}`
			else {
				return `${month}/${day}/${year}`
			}
		},
		confirmChangeRole() {
			let success = () => {
				this.$message.success("Successfully switched Role!");
				this.switchRole.obj.role = this.switchRole.role;
			};

			let url = `/uipermissions/${this.switchRole.obj.id}/:instance/${this.switchRole.obj.user.id}/${this.switchRole.role.id}`;
			this.$api
				.get(url)
				.then(({ data }) => {
					let newUser = JSON.parse(JSON.stringify(data));

					let users = this.$store.state.instance.users || [];
					let index = users.findIndex((x) => x.id === newUser.id);
					users[index].role = this.switchRole.role.id;
					this.$store.commit("SET_USERS", users);
					let roleCounts = this.computeRoleCounts();
					this.$store.commit("UPDATE_ROLE_COUNTS", roleCounts);

					success();
				})
				.catch((err) => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, "Error while changing user role. Please try again!"))
					}
				});
		},

		closeUser() {
			this.$store.commit("CLOSE_ADD_USER");
			this.inviteList = [];
		},

		convertDate(num) {
			return moment(num).format(this.dateFormat);
		},

		async fetchUsers() {
			this.loading = true;
			let instance = this.$store.state.instance;
			let { data } = await this.$api.get(
				"/instances/" + instance.id + "/users"
			).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
			if (data.id) {
				let users = JSON.parse(JSON.stringify(data.users));
				users = users.filter(x => x.role && x.role.id);
				users.forEach((x) => {
					x.role = x.role.id;
				});

				this.$store.commit("SET_USERS", users);
				this.instance = data;
				let roles = data.roles.map((role) => {
					return role;
				});

				if (this.instance.package && !["PA-COM-LRP", "PA-CON-HRP"].includes(this.instance.package.itemCode)) {
					roles = roles.filter(r => !['bh_agent', 'bh_agent_manager'].includes(r.name))
				}

				this.$store.commit("SET_ROLES", roles);

				let roleCounts = this.computeRoleCounts();
				this.$store.commit("UPDATE_ROLE_COUNTS", roleCounts);
				this.invite.role = roles[0].id;
			}
			this.loading = false;
		},

		async updateProjectOwner() {
			if (this.instance.billingOwner !== this.user.user.id) {
				return;
			}
			this.loading = true;
			try {
				await this.$api.post(`/instances/:instance/change-instance-owner`, {...this.updatedProjectOwner} );
				this.$message.success("Project Owner details updated successfully");
				this.changeProjectOwnerModal = false;
				this.fetchUsers();
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, "Error while updating project owner details. Please try again!"))
				}
			}
			this.loading = false;
		},

		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			);
		},

		fetchInstances() {
			this.$api.get('/instances').then(({ data }) => {
				data = data.filter(x => x.role && x.role.name === 'bh_admin').map(x => {
					return {
						id: x.instance.id,
						name: x.instance.name
					}
				})
				this.instances = data
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		},

		exportRoles() {
			let self = this;
			this.$refs.exportModal && this.$refs.exportModal.validate(async (valid) => {
				if (valid) {
					self.loading = true
					try {
						await self.$api.post(`/instances/clone-roles`, { from: self.instance.id, to: self.exportModal.instance })
						self.loading = false
						self.$message.success('Successfully exported all the roles into selected project.')
						self.exportModal = {
							visible: false,
							instance: null
						}
					} catch (err) {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while exporting roles to another project. Please try again.`))
						}
					}
				} else {
					return false;
				}
			})
		},

		beforeUpload(file) {
			const isCsv = file.type.includes('csv')

			if (file.type == '' && !file.name.includes('.')) {
				this.$message.error('A folder cannot be dragged and dropped in. Only individual files can be added.')
				return false;
			}
			if ((file.name.includes('.') && !isCsv) || file.type == '') {
				this.errorFileList.push({ name: file.name })
				this.$message.error(`File type is not supported for file "${file.name}"`)
				return false;
			}
			const isLt2M = (file, max = 2) => file.size / 1024 / 1024 < max;

			if (!isLt2M(file, 15)) {
				this.errorFileList.push({ name: file.name })
				this.$message.error('File must be smaller than 15MB!');
				return false;
			}

			this.fetchEmails(file)
			return false;
		},

		async fetchEmails(file) {
			this.processingFile = true;
			this.bulkInvite.emails = [];
			try {
				const text = await this.readFileAsText(file);
				const rows = text.split('\n'); // Split CSV data by newline to get rows
				// Trim whitespace from each row & Remove the empty values
				let emails = rows.map(row => row.trim()).filter(r => !!r)
				// Remove the first header value
				emails = emails.slice(1);
				if (!emails.length) {
					return this.$message.error('No valid emails found. Please upload valid file.')
				}
				this.bulkInvite.emails = emails;
				if (emails.length && this.roles.length) {
					this.bulkInvite.role = this.roles[0].id
				}
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error reading CSV file:'))
				}
			}
			this.processingFile = false;
		},

		readFileAsText(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result);
				reader.onerror = () => reject(reader.error);
				reader.readAsText(file);
			});
		},

		bulkInviteEmails() {
			let self = this;
			this.$refs.bulkInvite.validate(async (valid) => {
				if (valid) {
					self.loading = true
					try {
						await self.$api.post(`user-invites/:instance/${this.bulkInvite.role}/bulk`, {
							emails: this.bulkInvite.emails
						})
						self.loading = false
						self.$message.success('Successfully sent invitations to all the emails on the list.')
						self.closeBulkInvite();
						self.fetchUsers();
						self.fetchInstances();
					} catch (err) {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while inviting bulk emails. Please try again.`))
						}
					}
				}
			})
		},

		closeBulkInvite() {
			this.loading = false;
			this.processingFile = false;
			this.bulkInvite = {
				role: null,
				emails: []
			}
			this.bulkInviteModal = false;
		}
	},

	async created() {
		this.fetchUsers();
		this.fetchInstances();
	},
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
.contacts-page {
    @media screen and (min-width: 567px) {
        max-width: calc(100% - 35px);
    }
}
.aB {
    align-items: baseline;
}
.more-option-icon {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    text-align: center;
    background-color: transparent;
}
.more-option-icon:hover {
    color: var(--orange) !important;
    background-color: var(--light-gray);
}

.popoverContent {
    width: 200px;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #3f3356;
    color: white;
}

.action-buttons {
    cursor: pointer;
    color: #9ea0a5;
    transition: color 0.1s ease-in;
}
.action-buttons:hover {
    color: orange;
    transition: color 0.1s ease-in;
}
.status {
    line-height: 2.2;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    height: 32px;
    width: 90px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    border-color: #d9d9d9;
    color: white;
    user-select: none;
}
</style>
<style>
.cancel-button.ant-btn {
    border-color: #ece9f1 !important;
    background-color: #ece9f1;
    color: #3f3356;
}
</style>
<style lang="scss">
.popoverStyle2 .ant-popover-inner-content {
    padding: 0;
    background-color: white;
    border-radius: 20px;
}
.popoverStyle2 .ant-popover-content .ant-popover-arrow {
    border-top-color: #3f3356;
    border-right-color: #3f3356;
    border-bottom-color: #3f3356;
    border-left-color: #3f3356;
    background-color: #3f3356;
}
.tableStyle .ant-table-thead > tr > th {
    background-color: white;
}
.users-table .ant-table table {
    border-left: 1px solid #e4e7eb;
    border-right: 1px solid #e4e7eb;
    border-top: 1px solid #e4e7e8;
    border-radius: 0;
}
.users-table .ant-table-thead > tr > th {
    background: none;
}
.users-table .ant-table .ant-table-title {
    border: 1px solid #e4e7e8;
    padding: 16px 16px;
}
.users-table .ant-table {
    background-color: white;
}
.users-table .ant-table-title {
    top: 0;
}
.users-table .ant-table-content {
    top: -1px;
}

.list-unstyled .index_item_3A1RN {
    margin-right: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.ps--active-x > .ps__rail-x {
    display: none;
}
.rolesCard {
    background-color: #f7f5f9;
    border: none;
}
.rolesCard .ant-card-body {
    background-color: white;
    border: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
}

.ant-dropdown-menu-item:hover {
	color: var(--primary)
}
</style>
